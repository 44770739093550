import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { getBaseRoute } from '../libs/getBaseRoute';

export default function useFetchTokenKey(tokenparam, organizationId) {
  const [secret, setSecret] = useState('');
  const handleFetchTokenKey = useCallback(async () => {
    try {
      let storageOrgId = window.localStorage.getItem('organizationId');

      // WE ARE COMPARING IF ORG HAS CHANGED ->IF IT CHANGES WE NEED TO SEND NEW API REQUEST FOR TOKEN
      if (window.localStorage.getItem('tokenKey') && storageOrgId * 1 === organizationId * 1) {
        setSecret(window.localStorage.getItem('tokenKey'));
        return;
      }
      const res = await axios({
        method: 'Get',
        url: `${getBaseRoute}/protection?action=verifySubtitlingToken&token=${tokenparam}&_=${Date.now()}`,
      });
      console.log('dfdfdfdfd=>', res.data.secret);
      if (res?.data?.secret) {
        window.localStorage.setItem('tokenKey', res.data.secret);
      }

      window.localStorage.setItem('organizationId', organizationId);

      setSecret(res.data.secret);
    } catch (error) {
      console.log(error);
    }
  }, [tokenparam, organizationId]);

  useEffect(() => {
    tokenparam && tokenparam !== '' && organizationId && handleFetchTokenKey();
  }, [tokenparam, handleFetchTokenKey, organizationId]);

  return secret;
}
