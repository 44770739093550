import styled from 'styled-components';
import languages from '../libs/languages';
import { t, Translate } from 'react-i18nify';
import React, { useState, useCallback, useEffect } from 'react';
import googleTranslate from '../libs/googleTranslate';
import DT from 'duration-time-conversion';
import { getKeyCode } from '../utils';
import { file2sub } from '../libs/readSub';
import { getExt } from '../utils';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
  background-color: rgb(0 0 0 / 100%);
  border-left: 1px solid rgb(255 255 255 / 20%);

  .top {
    background-color: #272727;
    height: 100%;
    overflow-y: scroll;
  }

  .assets {
    background: #272727;
    padding: 10px 10px;
    color: #fff;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    & > div {
      display: flex;
      flex-direction: column;

      label {
        font-size: 13px;
        font-weight: 500;
      }

      span {
        font-size: 12px;
        color: #0a7aad;
      }
    }
  }

  .import {
    padding: 5px 10px 10px 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #0a7aad;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }

    .file {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .burn {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 100%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #673ab7;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .export {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 31%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #009688;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 20%);

    .btn {
      position: relative;
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 48%;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #009688;
      transition: all 0.2s ease 0s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .translate {
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    background-color: #272727;

    h3 {
      margin: 10px;
    }

    select {
      width: 93%;
      outline: none;
      padding: 10px 5px;
      margin: 10px;
      border-radius: 3px;
      font-size: 13px;
    }

    .btn {
      opacity: 0.85;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      font-size: 13px;
      background-color: #0a7aad;
      transition: all 0.2s ease 0s;
      margin: 10px;
      padding: 10px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .hotkey {
    text-align: center;
    background: #272727;
    height: inherit;

    h3 {
      padding: 0 0 0 15px;
      text-align: initial;
    }

    div {
      font-size: 13px;
      padding: 10px 0;
      margin: 10px;
      border-radius: 3px;
      text-align: center;
      color: rgb(255 255 255 / 75%);
      background-color: rgb(255 255 255 / 20%);
    }
  }

  .bottom {
    padding: 10px;
    a {
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(255 255 255 / 30%);
      text-decoration: none;

      .title {
        color: #ffeb3b;
        padding: 5px 10px;
        animation: animation 3s infinite;
        border-bottom: 1px solid rgb(255 255 255 / 30%);
      }

      @keyframes animation {
        50% {
          color: #00bcd4;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .progress {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9;
    height: 2px;
    background-color: rgb(0 0 0 / 50%);

    span {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 0;
      height: 100%;
      background-color: #ff9800;
      transition: all 0.2s ease 0s;
    }
  }
`;

function findIndex(subs, startTime) {
  return subs.findIndex((item, index) => {
    return (
      (startTime >= item.endTime && !subs[index + 1]) ||
      (item.startTime <= startTime && item.endTime > startTime) ||
      (startTime >= item.endTime && subs[index + 1] && startTime < subs[index + 1].startTime)
    );
  });
}

//find starttime, endtime for cue
function findFlag(subs, currentTime) {
  let result = 0;
  for (let i = 0; i < subs.length; i++) {
    if (subs[i].startTime <= currentTime && subs[i].endTime > currentTime) {
      result = 1;
      break;
    }
  }
  return result;
}

function findMethod(subs, currentTime, index, flag) {
  let result = 0;
  if (flag === 0) {
    if (!subs[index]) {
      result = 0;
    } else {
      if (subs[index].startTime > currentTime) {
        result = 0;
      } else {
        result = 1;
      }
    }
  } else {
    result = -1;
  }

  return result;
}

function ShortcutAddMethod(subs, currentTime, idx) {
  let result = currentTime;
  if (subs[idx - 1] && subs[idx - 1].startTime <= currentTime && subs[idx - 1].endTime >= currentTime) {
    alert(t('ADD_ERROR_TEXT'));
    result = null;
  } else if (subs[idx] && subs[idx].startTime - currentTime <= 0.3) {
    alert(t('ADD_ERROR_TEXT'));
    result = null;
  }

  return result;
}

// function findCorrectEndTime(subs, cueStartTime, currentTime, index) {
//   let result = currentTime;

//   if (cueStartTime < currentTime) {
//     if (subs[index]) {
//       if (
//         (currentTime >= subs[index].startTime && currentTime <= subs[index].endTime) ||
//         currentTime > subs[index].endTime
//       ) {
//         result = subs[index].startTime - 0.01;
//       }
//     }
//   }
//   return result;
// }

export default function Header({
  currentTime,
  language,
  subtitle,
  setLoading,
  formatSub,
  setSubtitle,
  notify,
  assets,
  player,
  playing,
  newSub,
  addSub,
  updateSub,
  clearSubs,
  setLanguageId,
  subtitleTitle,
  translatedId,
  setTranslatedId,
}) {
  const [translate, setTranslate] = useState('en');

  const [cueStartTime, setCueStartTime] = useState(-1);
  const [cueEndTime, setCueEndTime] = useState(-1);
  const [subflag, setSubFlag] = useState(0);

  let sflag = 0;

  const onTranslate = useCallback(() => {
    setLoading(t('TRANSLATING'));
    googleTranslate(formatSub(subtitle), translate)
      .then((res) => {
        setLoading('');
        setSubtitle(formatSub(res));
        setTranslatedId(translate);
        notify({
          message: t('TRANSLAT_SUCCESS'),
          level: 'success',
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading('');
        notify({
          message: err.message,
          level: 'error',
        });
      });
  }, [subtitle, setLoading, formatSub, setSubtitle, translate, notify]);

  // Cue-in, Cue-out setting
  const onArrowLeftKeyDown = () => {
    let correctStartTime = currentTime;
    if (subtitle.length > 0) {
      sflag = findFlag(subtitle, currentTime);
    }
    setCueStartTime(correctStartTime);
  };

  const onArrowRightKeyDown = () => {
    let correctEndTime = currentTime;
    const index = findIndex(subtitle, cueStartTime) + 1;
    sflag = findFlag(subtitle, cueStartTime);
    setSubFlag(() => findMethod(subtitle, currentTime, index, sflag));
    setCueEndTime(correctEndTime);
  };

  //Add new subtitle on current time

  const onPlusKeyDown = () => {
    const idx = findIndex(subtitle, currentTime) + 1;
    let addflag = ShortcutAddMethod(subtitle, currentTime, idx);
    if (addflag !== null) {
      const addendtime = currentTime + 0.3;
      const start = DT.d2t(currentTime);
      const end = DT.d2t(addendtime);
      addSub(
        idx,
        newSub({
          start,
          end,
          text: '',
          text2: t('SUB_TEXT'),
        }),
      );
    }
  };

  const onKeyDown = (event) => {
    const keyCode = getKeyCode(event);
    switch (keyCode) {
      case 37:
        let correctStartTime = currentTime;
        if (subtitle.length > 0) {
          sflag = findFlag(subtitle, currentTime);
        }
        setCueStartTime(correctStartTime);
        break;
      case 39:
        let correctEndTime = currentTime;
        const index = findIndex(subtitle, cueStartTime) + 1;
        sflag = findFlag(subtitle, cueStartTime);
        setSubFlag(() => findMethod(subtitle, currentTime, index, sflag));
        setCueEndTime(correctEndTime);
        break;
      case 187:
        const idx = findIndex(subtitle, currentTime) + 1;
        let addflag = ShortcutAddMethod(subtitle, currentTime, idx);
        if (addflag !== null) {
          const addendtime = currentTime + 0.3;
          const start = DT.d2t(currentTime);
          const end = DT.d2t(addendtime);
          addSub(
            idx,
            newSub({
              start,
              end,
              text: '',
              text2: t('SUB_TEXT'),
            }),
          );
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  useEffect(() => {
    if (subflag === 0) {
      if (cueStartTime > 0 && cueEndTime > 0 && cueEndTime - cueStartTime >= 0.2) {
        const index = findIndex(subtitle, cueStartTime) + 1;
        const start = DT.d2t(cueStartTime);
        const end = DT.d2t(cueEndTime);
        addSub(
          index,
          newSub({
            start,
            end,
            text: '',
            text2: t('SUB_TEXT'),
          }),
        );
      }
      setCueStartTime(-1);
      setCueEndTime(-1);
    } else if (subflag === 1) {
      if (cueStartTime > 0 && cueEndTime > 0 && cueEndTime - cueStartTime >= 0.2) {
        const index = findIndex(subtitle, cueStartTime) + 1;
        const currentSub = subtitle[index];
        const start = DT.d2t(cueStartTime);
        const end = DT.d2t(cueEndTime);
        updateSub(currentSub, { start, end });
      }
      setCueStartTime(-1);
      setCueEndTime(-1);
    } else if (subflag === -1) {
      if (cueStartTime > 0 && cueEndTime > 0 && cueEndTime - cueStartTime >= 0.2) {
        const index = findIndex(subtitle, cueStartTime) + 1;
        const currentSub = subtitle[index - 1];
        const start = DT.d2t(cueStartTime);
        const end = DT.d2t(cueEndTime);
        updateSub(currentSub, { start, end });
      }
      setCueStartTime(-1);
      setCueEndTime(-1);
    }
  }, [cueEndTime]);

  const onSubtitleChange = useCallback(
    (event) => {
      const file = event.target.files[0];

      if (file) {
        const ext = getExt(file.name);

        if (['ass', 'vtt', 'srt', 'json', 'txt'].includes(ext)) {
          file2sub(file)
            .then((res) => {
              console.log('eeee=>', res);
              clearSubs();
              setSubtitle(res);
            })
            .catch((err) => {
              console.log('eeee=>', err);
              notify({
                message: err.message,
                level: 'error',
              });
            });
        } else {
          notify({
            message: `${t('SUB_EXT_ERR')}: ${ext}`,
            level: 'error',
          });
        }
      }
    },
    [notify, setSubtitle, clearSubs],
  );

  const onInputClick = useCallback((event) => {
    event.target.value = '';
  }, []);

  return (
    <Style className="tool">
      <div className="top">
        <div className="assets">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <h3>{assets?.name?.[assets?.defaultLanguage] || assets?.name?.['en_US']} </h3>
              <div style={{ marginLeft: '5px' }}> -</div>
            </div>
            <span
              style={{
                marginLeft: '5px',
              }}
            >
              {assets?.id}
            </span>
          </div>

          {subtitleTitle && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <label>Subtitle asset name -</label>
              <span
                style={{
                  marginLeft: '5px',
                }}
              >
                {subtitleTitle}
              </span>
            </div>
          )}
        </div>

        <div className="translate">
          <h3>Translate To</h3>
          <select value={translate} onChange={(event) => setTranslate(event.target.value)}>
            {(languages[language] || languages.en).map((item) => (
              <option key={item.key} value={item.key}>
                {item.name}
              </option>
            ))}
          </select>
          <div className="btn" onClick={onTranslate}>
            <Translate value="TRANSLATE" />
          </div>
        </div>
        <div className="import">
          <h3>Upload file for editing</h3>
          <div className="btn">
            <Translate value="OPEN_TXT" />
            <input className="file" type="file" onChange={onSubtitleChange} onClick={onInputClick} />
          </div>
        </div>
        <div className="hotkey">
          <h3>Shortcuts</h3>
          <div>
            <Translate value="HOTKEY_01" />
          </div>
          <div>
            <Translate value="HOTKEY_02" />
          </div>
          <div onClick={() => onArrowLeftKeyDown()}>
            <Translate value="HOTKEY_03" />
          </div>
          <div onClick={() => onArrowRightKeyDown()}>
            <Translate value="HOTKEY_04" />
          </div>
          <div onClick={() => onPlusKeyDown()}>
            <Translate value="HOTKEY_05" />
          </div>
        </div>
      </div>
    </Style>
  );
}
