import styled from 'styled-components';
import React, { useState, useCallback, useEffect } from 'react';
import { Table, Column } from 'react-virtualized';
import unescape from 'lodash/unescape';
import debounce from 'lodash/debounce';
import DT from 'duration-time-conversion';
import { t } from 'react-i18nify';
import Timestamps from './Timestamps';

const Style = styled.div`
  position: relative;
  box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
  background-color: rgb(0 0 0 / 100%);

  .ReactVirtualized__Table {
    .ReactVirtualized__Table__Grid {
      outline: none;
      background: #141922;
      border-left: 4px solid #0a7aad;
    }

    .ReactVirtualized__Table__headerRow {
      padding-right: 0px;
      display: flex;
      text-align: center;
      font-size: 13px;
      background: #0a7aad;
    }

    .ReactVirtualized__Table__row {
      display: flex;
      border-bottom: 1px solid #000;

      .setting {
        width: 7%;
        display: grid;
        padding-top: 16px;
        padding-left: 10px;

        img {
          cursor: pointer;
        }
      }

      .timestamp {
        width: 33%;
        text-align: center;
      }

      .duration {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .item {
        width: 50%;
        height: 100%;
        padding: 5px;

        .textarea {
          border: none;
          width: 100%;
          height: 100%;
          color: #fff;
          font-size: 12px;
          padding: 10px;
          text-align: center;
          background-color: #394855;
          border: 1px solid rgba(255, 255, 255, 0.1);
          transition: all 0.2s ease;
          resize: none;
          outline: none;

          &.highlight {
            background-color: #0a7aad;
            border: 1px solid rgba(255, 255, 255, 0.3);
          }

          &.illegal {
            background-color: rgb(123 29 0);
            border: 1px solid rgba(255, 255, 255, 0.3);
          }
        }

        textarea::placeholder {
          color: #fff;
        }
      }
    }
  }

  .firstAdd_btn {
    padding: 10px;
    font-size: 13px;
    background: #0a7aad;
    margin: 5% 30% 0 30%;
    text-align: center;
    border-radius: 3px;
    opacity: 0.85;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease 0s;

    &:hover {
      opacity: 1;
    }
  }
`;

function findIndex(subs, startTime) {
  return subs.findIndex((item, index) => {
    return (
      (startTime >= item.endTime && !subs[index + 1]) ||
      (item.startTime <= startTime && item.endTime > startTime) ||
      (startTime >= item.endTime && subs[index + 1] && startTime < subs[index + 1].startTime)
    );
  });
}

export default function Subtitles({ currentTime, subtitle, checkSub, player, updateSub, removeSub, addSub, newSub }) {
  const [height, setHeight] = useState(100);

  const resize = useCallback(() => {
    setHeight(document.body.clientHeight - 240);
  }, [setHeight]);

  useEffect(() => {
    resize();
    if (!resize.init) {
      resize.init = true;
      const debounceResize = debounce(resize, 500);
      window.addEventListener('resize', debounceResize);
    }
  }, [resize]);

  const addsubtitle = (addstarttime) => {
    const idx = findIndex(subtitle, addstarttime) + 1;

    if (subtitle[idx] && subtitle[idx].startTime - subtitle[idx - 1].endTime <= 0.3) {
      alert(t('ADD_ERROR_TEXT'));
    } else {
      addstarttime = addstarttime + 0.01;
      const addendtime = addstarttime + 0.3;
      const start = DT.d2t(addstarttime);
      const end = DT.d2t(addendtime);
      addSub(
        idx,
        newSub({
          start,
          end,
          text: '',
          text2: t('SUB_TEXT'),
        }),
      );

      console.log('updatesubtitle=>', subtitle);
    }
  };

  //function that one of subtitles is activated at any time
  const getCurrentIndex = (subs) => {
    const numSubs = subs.length;
    if (numSubs === 0) {
      return -1;
    }
    if (numSubs <= 1) {
      if (subs[0].startTime <= currentTime) {
        return 0;
      }
      return -1;
    }
    for (let i = 0; i < numSubs; i++) {
      if (i === numSubs - 1 && subs[i].startTime <= currentTime) {
        return i;
      } else {
        let prevSub = subs[i];
        let nextSub = subs[i + 1];
        if (prevSub.startTime <= currentTime && nextSub.startTime > currentTime) {
          return i;
        }
      }
    }
    return -1;
  };
  const currentIndex = getCurrentIndex(subtitle);

  return (
    <Style className="subtitles">
      <Table
        headerHeight={20}
        width={500}
        height={height}
        rowHeight={80}
        scrollToIndex={currentIndex}
        rowCount={subtitle.length === 0 ? 1 : subtitle.length}
        rowGetter={({ index }) => {
          if (subtitle.length === 0) {
            return 0;
          } else {
            return subtitle[index];
          }
        }}
        rowRenderer={(props) => {
          return (
            <>
              {subtitle.length === 0 && (
                <div className="firstAdd_btn" onClick={() => addsubtitle(0)}>
                  Add Subtitle
                </div>
              )}
              {subtitle.length > 0 && (
                <div
                  key={props.key}
                  className={props.className}
                  style={props.style}
                  onClick={() => {
                    if (player) {
                      player.pause();
                      if (player.duration >= props.rowData.startTime) {
                        player.currentTime = props.rowData.startTime + 0.001;
                      }
                    }
                  }}
                >
                  <div className="setting">
                    <img src="/delete_icon.png" width="15px" onClick={() => removeSub(props.rowData)} alt="delete" />
                    <img
                      src="/plus_icon.png"
                      width="15px"
                      onClick={() => addsubtitle(props.rowData.endTime)}
                      alt="plus_icon"
                    />
                  </div>
                  <div className="timestamp">
                    <Timestamps
                      {...props}
                      subtitle={subtitle}
                      currentIndex={currentIndex}
                      updateSub={updateSub}
                      removeSub={removeSub}
                    />
                  </div>
                  <div className="duration">
                    <p>{props.rowData.duration}</p>
                  </div>
                  <div className="item">
                    <textarea
                      maxLength={200}
                      spellCheck={false}
                      className={[
                        'textarea',
                        currentIndex === props.index ? 'highlight' : '',
                        checkSub(props.rowData) ? 'illegal' : '',
                      ]
                        .join(' ')
                        .trim()}
                      placeholder={unescape(props.rowData.text2)}
                      value={unescape(props.rowData.text)}
                      onChange={(event) => {
                        updateSub(props.rowData, {
                          text: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          );
        }}
      >
        <Column
          width={45}
          dataKey={'setting'}
          headerRenderer={() => <img src="/setting_icon.png" width="10px" alt="setting" />}
        />
        <Column width={155} dataKey={'timestamp'} headerRenderer={() => 'Timestamp'} />
        <Column width={50} dataKey={'duration'} headerRenderer={() => 'Duration'} />
        <Column width={250} dataKey={'subtitle'} headerRenderer={() => 'Main Subtitle'} />
      </Table>
    </Style>
  );
}
