import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import DT from 'duration-time-conversion';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import Tool from './components/Tool';
import Subtitles from './components/Subtitles';
import Player from './components/Player';
import Footer from './components/Footer';
import Loading from './components/Loading';
import ProgressBar from './components/ProgressBar';
import { getKeyCode } from './utils';
import Sub from './libs/Sub';
import axios from 'axios';
import { sub2vtt, sub2txt } from './libs/readSub';
import crypto from 'crypto';
import { t } from 'react-i18nify';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useFetchTokenKey from './hook/useFetchTokenKey';
import { isUndefined } from 'lodash';
import { getBaseRoute } from './libs/getBaseRoute';
import { getAsset } from './libs/getAsset';
import { getVideoURL } from './libs/getVideoURL';

const Style = styled.div`
  height: 100%;
  width: 100%;

  .toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 80px;
    background: #313131;
    height: 100vh;

    .btn {
      padding: 0;
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;

      .close {
        width: 20px;
      }

      img {
        width: 25px;
        filter: invert(1) opacity(0.9);
        margin-bottom: 8px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: #eee;
      }
    }
  }

  .main {
    padding-left: 80px;
    display: flex;
    height: calc(100% - 200px);

    .player {
      flex: 1;
    }

    .subtitles {
      width: 500px;
    }

    .tool {
      width: 300px;
    }
  }

  .footer {
    height: 200px;
  }
`;

const baseURL_3 = `${getBaseRoute}/archive`;

export default function App({ defaultLang }) {
  const subtitleHistory = useRef([]);
  const notificationSystem = useRef(null);
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState('');
  const [processing, setProcessing] = useState(0);
  const [language, setLanguage] = useState(defaultLang);
  const [subtitle, setSubtitleOriginal] = useState([]);
  const [waveform, setWaveform] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [assets, setAssets] = useState(null);
  const [videoAsset, setVideoAsset] = useState(null);
  const [saveAsOpen, setSaveAsOpen] = useState(false);
  const [filename, setFileName] = useState('');
  const [subtitleTitle, setSubtitleTitle] = useState('');
  const [subtitleId, setSubtitleId] = useState(null);
  const [tokenParam, setTokenParam] = useState('');
  const [btnflag, setBTNFlag] = useState(0);
  const [videourl, setVideoURL] = useState('');
  const [languageId, setLanguageId] = useState('en');
  const [translatedId, setTranslatedId] = useState('');

  const [authLoaded, setAuthLoaded] = useState(false);

  //Function that get params from URL
  function getParam() {
    return new URLSearchParams(window.location.search.toString());
  }

  const [organizationId, setOrganizationId] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [userId, setUserId] = useState(null);
  let tokenKey = useFetchTokenKey(tokenParam, organizationId);

  const new_subtitle_id = getParam().get('subtitleId');

  useEffect(() => {
    setOrganizationId(getParam().get('organizationId'));
    setAssetId(getParam().get('assetId'));
    setTokenParam(getParam().get('token'));
    setUserId(getParam().get('userId'));

    let subtitle_Id = getParam().get('subtitleId');

    if (subtitle_Id !== null) {
      setSubtitleId(subtitle_Id);
    }

    if (getParam().get('langId')) {
      let language_Id = getParam().get('langId');
      setLanguageId(language_Id);

      language_Id = language_Id.slice(0, 2);
      setTranslatedId(language_Id);
    }
  }, [new_subtitle_id]);

  let newsubtitleId = null;

  //Open Save As Dialog
  const handleClickOpen = (_flag) => {
    setBTNFlag(_flag);
    if ((subtitleId && _flag === 1) || (newsubtitleId && _flag === 1)) {
      saveSub('vtt', _flag);
    } else if (subtitleTitle && _flag === 3) {
      saveSub('vtt', _flag);
    } else {
      setSaveAsOpen(true);
    }
  };
  //Close Save As Dialog
  const handleClose = () => {
    setSaveAsOpen(false);
  };

  const newSub = useCallback((item) => new Sub(item), []);
  const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle]);

  const formatSub = useCallback(
    (sub) => {
      if (Array.isArray(sub)) {
        return sub.map((item) => newSub(item));
      }
      return newSub(sub);
    },
    [newSub],
  );

  const copySubs = useCallback(() => formatSub(subtitle), [subtitle, formatSub]);

  const setSubtitle = useCallback(
    (newSubtitle, saveToHistory = true) => {
      if (!isEqual(newSubtitle, subtitle)) {
        if (saveToHistory) {
          if (subtitleHistory.current.length >= 1000) {
            subtitleHistory.current.shift();
          }
          subtitleHistory.current.push(formatSub(subtitle));
        }
        window.localStorage.setItem('subtitle', JSON.stringify(newSubtitle));
        setSubtitleOriginal(newSubtitle);
      }
    },
    [subtitle, setSubtitleOriginal, formatSub],
  );

  const undoSubs = useCallback(() => {
    const subs = subtitleHistory.current.pop();
    if (subs) {
      setSubtitle(subs, false);
    }
  }, [setSubtitle, subtitleHistory]);

  const clearSubs = useCallback(() => {
    setSubtitle([]);
    subtitleHistory.current.length = 0;
  }, [setSubtitle, subtitleHistory]);

  const checkSub = useCallback(
    (sub) => {
      const index = hasSub(sub);
      if (index < 0) return;
      const previous = subtitle[index - 1];
      return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.2;
    },
    [subtitle, hasSub],
  );

  const notify = useCallback(
    (obj) => {
      // https://github.com/igorprado/react-notification-system
      const notification = notificationSystem.current;
      notification.clearNotifications();
      notification.addNotification({
        position: 'tc',
        dismissible: 'none',
        autoDismiss: 2,
        message: obj.message,
        level: obj.level,
      });
    },
    [notificationSystem],
  );

  const removeSub = useCallback(
    (sub) => {
      const index = hasSub(sub);
      if (index < 0) return;
      const subs = copySubs();
      subs.splice(index, 1);
      setSubtitle(subs);
    },
    [hasSub, copySubs, setSubtitle],
  );

  const addSub = useCallback(
    (index, sub) => {
      const subs = copySubs();
      subs.splice(index, 0, formatSub(sub));
      setSubtitle(subs);
    },
    [copySubs, setSubtitle, formatSub],
  );

  const updateSub = useCallback(
    (sub, obj) => {
      const index = hasSub(sub);
      if (index < 0) return;
      const subs = copySubs();
      const subClone = formatSub(sub);
      Object.assign(subClone, obj);
      if (subClone.check) {
        subs[index] = subClone;
        setSubtitle(subs);
      }
    },
    [hasSub, copySubs, setSubtitle, formatSub],
  );

  const mergeSub = useCallback(
    (sub) => {
      const index = hasSub(sub);
      if (index < 0) return;
      const subs = copySubs();
      const next = subs[index + 1];
      if (!next) return;
      const merge = newSub({
        start: sub.start,
        end: next.end,
        text: sub.text.trim() + '\n' + next.text.trim(),
      });
      subs[index] = merge;
      subs.splice(index + 1, 1);
      setSubtitle(subs);
    },
    [hasSub, copySubs, setSubtitle, newSub],
  );

  const splitSub = useCallback(
    (sub, start) => {
      const index = hasSub(sub);
      if (index < 0 || !sub.text || !start) return;
      const subs = copySubs();
      const text1 = sub.text.slice(0, start).trim();
      const text2 = sub.text.slice(start).trim();
      if (!text1 || !text2) return;
      const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(3);
      if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return;
      subs.splice(index, 1);
      const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration));
      subs.splice(
        index,
        0,
        newSub({
          start: sub.start,
          end: middleTime,
          text: text1,
        }),
      );
      subs.splice(
        index + 1,
        0,
        newSub({
          start: middleTime,
          end: sub.end,
          text: text2,
        }),
      );
      setSubtitle(subs);
    },
    [hasSub, copySubs, setSubtitle, newSub],
  );

  const saveSub = async (type, flag = 0) => {
    let name = null;
    let text = '';

    if (filename === '' && saveAsOpen === true) {
      alert('Please enter a file name');
      return;
    }

    if (filename !== '') {
      name = filename;
    } else {
      name = subtitleTitle;
    }

    switch (type) {
      case 'vtt':
        text = sub2vtt(subtitle);
        break;
      case 'txt':
        text = sub2txt(subtitle);
        break;
      default:
        break;
    }

    setSaveAsOpen(false);

    //Create new subtitle file
    let blob = new Blob([text], { type: 'plain/vtt' });
    let file = new File([blob], '1.vtt', { type: 'plain/vtt' });

    if (isUndefined(tokenKey)) {
      return;
    }
    // if (subtitleTitle && languageId == translatedId) {
    if (subtitleTitle === name || newsubtitleId) {
      //1.update existing subtitle file

      let url = `${baseURL_3}?organizationId=${organizationId}&userId=${userId}&token=${tokenKey}&action=addSubtitleFile&subtitleId=${
        newsubtitleId ?? subtitleId
      }`;

      const formData = new FormData();
      formData.append('subtitleFile', file);

      try {
        const response = await axios({
          method: 'post',
          url: url,
          data: formData,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': 'multipart/form-data;boundary=',
          },
        });
        console.log('response=>', response.data);
        if (response.data.status === 'ok') {
          notify({
            message: t('SAVE_SUCCESS'),
            level: 'success',
          });
        } else {
          console.log(response.data);
          notify({
            message: t('SAVE_ERROR'),
            level: 'error',
          });
        }
      } catch (error) {
        console.log(error);
        notify({
          message: t('SAVE_ERROR'),
          level: 'error',
        });
      }

      //2.Update existing subtitle asset
      let url2 = `${baseURL_3}?organizationId=${organizationId}&userId=${userId}&assetId=${assetId}&token=${tokenKey}&action=saveSubtitle&title=${name}&description=Hello_Icareus_friends&subtitleId=${subtitleId}`;

      try {
        const response1 = await axios({
          method: 'post',
          url: url2,
        });
        console.log('Update existing subtitle asset=>', response1);
        setFileName('');
        setBTNFlag(0);
        if (flag === 3) {
          //When user click close button
          window.close();
        }
      } catch (error) {
        console.log(error);
      }
    } else if (!newsubtitleId) {
      //Create and Upload subtitle asset and file
      console.log('create new subtitle');

      //1.Create new subtitle asset
      let url1 = `${baseURL_3}?organizationId=${organizationId}&userId=${userId}&assetId=${assetId}&token=${tokenKey}&action=saveSubtitle&title=${name}&description=Hello_Icareus_friends&subtitleId=${newsubtitleId}`;

      try {
        const response1 = await axios({
          method: 'post',
          url: url1,
        });
        console.log('response1=>', response1);
        // setNewsubtitleId(response1.data.subtitle.id);
        newsubtitleId = response1.data.subtitle.id;
        uploadnewSub(response1.data.subtitle.id, file);
      } catch (error) {
        console.log(error);
        notify({
          message: t('SAVE_ERROR'),
          level: 'error',
        });
      }
    }
  };

  const uploadnewSub = async (newid, file) => {
    if (isUndefined(tokenKey)) {
      return;
    }

    if (newid) {
      //2.Link Subtitle asset to an asset
      console.log('create new link for subtitle asset');
      let url2 = `${baseURL_3}?organizationId=${organizationId}&userId=${userId}&assetId=${assetId}&token=${tokenKey}&action=linkSubtitle&subtitleId=${newid}`;

      try {
        const response2 = await axios({
          method: 'post',
          url: url2,
        });
        console.log('response2=>', response2);
      } catch (error) {
        console.log(error);
        notify({
          message: t('SAVE_ERROR'),
          level: 'error',
        });
      }

      //3. Upload subtitle file
      let url3 = `${baseURL_3}?organizationId=${organizationId}&userId=${userId}&token=${tokenKey}&action=addSubtitleFile&subtitleId=${newid}`;

      const formData3 = new FormData();
      formData3.append('subtitleFile', file);

      try {
        const response = await axios({
          method: 'post',
          url: url3,
          data: formData3,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': 'multipart/form-data;boundary=',
          },
        });
        console.log('response=>', response);
        notify({
          message: t('SAVE_SUCCESS'),
          level: 'success',
        });

        setFileName('');
        setBTNFlag(0);
        if (btnflag === 3) {
          //When user click close button
          window.close();
        } else if (btnflag === 1) {
          const currentURL = window.location.href;
          window.location.href = currentURL + `&subtitleId=${newid}`;
        } else if (btnflag === 2) {
          let currentURL = window.location.href;
          let position = currentURL.lastIndexOf('subtitleId');
          currentURL = currentURL.slice(0, position);
          console.log('currentURL=>', currentURL);
          window.location.href = currentURL + `subtitleId=${newid}`;
        }
      } catch (error) {
        console.log(error);
        notify({
          message: t('SAVE_ERROR'),
          level: 'error',
        });
      }
    }
  };

  const onKeyDown = useCallback(
    (event) => {
      const keyCode = getKeyCode(event);
      switch (keyCode) {
        case 32:
          event.preventDefault();
          if (player) {
            if (playing) {
              player.pause();
            } else {
              player.play();
            }
          }
          break;
        case 90:
          event.preventDefault();
          if (event.metaKey) {
            undoSubs();
          }
          break;
        default:
          break;
      }
    },
    [player, playing, undoSubs],
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  useMemo(() => {
    const currentIndex = subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime);
    setCurrentIndex(currentIndex);
  }, [currentTime, subtitle]);

  useEffect(() => {
    const fetchAsset = async () => {
      const res = await getAsset(organizationId, assetId, tokenParam);
      console.log('res', res);

      setAssets(res?.data?.assets?.[0]);
      let URL = getVideoURL(res?.data?.assets?.[0]);

      setVideoURL(URL);
      setAuthLoaded(true);
    };

    if (organizationId && assetId && tokenParam) {
      fetchAsset();
    }
  }, [assetId, organizationId, tokenParam]);

  const props = {
    player,
    setPlayer,
    subtitle,
    setSubtitle,
    waveform,
    setWaveform,
    currentTime,
    setCurrentTime,
    currentIndex,
    setCurrentIndex,
    playing,
    setPlaying,
    language,
    setLanguage,
    loading,
    setLoading,
    setProcessing,
    subtitleHistory,
    assets,
    videoAsset,
    subtitleTitle,
    setSubtitleTitle,
    languageId,
    setLanguageId,
    translatedId,
    setTranslatedId,
    subtitleId,
    organizationId,
    assetId,
    userId,
    tokenKey,
    videourl,

    notify,
    newSub,
    hasSub,
    checkSub,
    removeSub,
    addSub,
    undoSubs,
    clearSubs,
    updateSub,
    formatSub,
    mergeSub,
    splitSub,
  };

  return (
    <Style>
      {!authLoaded ? (
        <Loading loading={loading} />
      ) : (
        <>
          {(!organizationId || !tokenKey) && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                fontSize: '40px',
              }}
            >
              Access not granted!
            </div>
          )}
          {organizationId && props?.videourl?.[0] ? (
            <>
              <div className="toolbar">
                <button className="btn" onClick={() => handleClickOpen(1)}>
                  <img src="/save.png" alt=":( Not Found" />
                  <span>Save</span>
                </button>
                <button className="btn" onClick={() => handleClickOpen(2)}>
                  <img src="/save.png" alt=":( Not Found" />
                  <span>Save As</span>
                </button>
                <button className="btn" onClick={() => handleClickOpen(3)}>
                  <img className="close" src="/close.png" alt=":( Not Found" />
                  <span>Close</span>
                </button>
                <Dialog open={saveAsOpen} onClose={handleClose}>
                  <DialogTitle>Subtitle File Name</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="file_name"
                      label="File Name"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={filename}
                      onChange={(e) => setFileName(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => saveSub('vtt')}>Save</Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div className="main">
                <Player {...props} />
                <Subtitles {...props} />
                <Tool {...props} />
              </div>
              <Footer {...props} />
              {loading ? <Loading loading={loading} /> : null}
              {processing > 0 && processing < 100 ? <ProgressBar processing={processing} /> : null}
              <NotificationSystem ref={notificationSystem} allowHTML={true} />
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                fontSize: '40px',
              }}
            >
              Video does not have any playable source
            </div>
          )}
        </>
      )}
    </Style>
  );
}
