import DT from 'duration-time-conversion';

export default function txt2vtt(txt) {
  const re_txt = new RegExp(
    'Dialogue:\\s\\d,' +
      '(\\d+:\\d\\d:\\d\\d.\\d\\d),' +
      '(\\d+:\\d\\d:\\d\\d.\\d\\d),' +
      '([^,]*),' +
      '([^,]*),' +
      '(?:[^,]*,){4}' +
      '([\\s\\S]*)$',
    'i',
  );

  // function fixTime(time = '') {
  //     return time
  //         .split(/[:.]/)
  //         .map((item, index, arr) => {
  //             if (index === arr.length - 1) {
  //                 if (item.length === 1) {
  //                     return '.' + item + '00';
  //                 } else if (item.length === 2) {
  //                     return '.' + item + '0';
  //                 }
  //             } else {
  //                 if (item.length === 1) {
  //                     return (index === 0 ? '0' : ':0') + item;
  //                 }
  //             }

  //             return index === 0 ? item : index === arr.length - 1 ? '.' + item : ':' + item;
  //         })
  //         .join('');
  // }

  let startTime = 0;
  let endTime = 0;

  return (
    'WEBVTT\n\n' +
    txt
      .split(/\r\n\r\n|\n\n/)
      .map((line, index) => {
        if (index != 0) {
          startTime = endTime + 0.01;
        }
        endTime = startTime + 0.3;

        return {
          start: DT.d2t(startTime),
          end: DT.d2t(endTime),
          text: line,
        };
      })
      .filter((line) => line)
      .map((line, index) => {
        if (line) {
          console.log('line', line);
          return index + 1 + '\n' + line.start + ' --> ' + line.end + '\n' + line.text;
        } else {
          console.log('haha');
          return '';
        }
      })
      .filter((line) => line.trim())
      .join('\n\n')
  );
}
