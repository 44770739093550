import axios from 'axios';
import { getBaseRoute } from './getBaseRoute';

export const getAsset = (organizationId, assetId, token) => {
  // SEEMS LIKE WE DONT NEED TO PARSE THE TOKEN
  const url = `${getBaseRoute}/archive?action=getOrganizationAssets&subtitlingToken=${token}&organizationId=${organizationId}&assetId=${Number(
    assetId,
  )}&_=${Date.now()}`;

  return axios.get(url);
};
