import React, { useState, useEffect } from 'react';
import DT from 'duration-time-conversion';
import styled from 'styled-components';

const Style = styled.div`
  input {
    width: 85%;
    text-align: center;
    background: #000;
    color: white;
    border: unset;
    margin-top: 12px;
    border: 1px solid #000;
    outline: unset;
  }
  input.invalid {
    color: red;
    border: 1px solid red;
  }
`;

export default function Timestamps(props) {
  const [starttime, setStartTime] = useState(props.rowData.start);
  const [endtime, setEndTime] = useState(props.rowData.end);
  const [startClass, setStartClass] = useState('');
  const [endClass, setEndClass] = useState('');
  const [startflag, setStartFlag] = useState(false);
  const [endflag, setEndFlag] = useState(false);

  useEffect(() => {
    setStartTime(props.rowData.start);
  }, [props.rowData.start]);

  useEffect(() => {
    setEndTime(props.rowData.end);
  }, [props.rowData.end]);

  useEffect(() => {
    let start = starttime;
    if (startflag && props.currentIndex > 0) {
      start = DT.t2d(start);
      if (
        start <= props.subtitle[props.currentIndex - 1].endTime &&
        start >= props.subtitle[props.currentIndex - 1].startTime
      ) {
        const end = DT.d2t(start - 0.001);
        props.updateSub(props.subtitle[props.currentIndex - 1], { end });
      } else if (start < props.subtitle[props.currentIndex - 1].startTime) {
        props.removeSub(props.subtitle[props.currentIndex - 1]);
      }

      setStartFlag(false);
    }
  }, [startflag]);

  useEffect(() => {
    let end = endtime;
    if (endflag && props.currentIndex < props.subtitle.length - 1) {
      end = DT.t2d(end);
      if (
        end <= props.subtitle[props.currentIndex + 1].endTime &&
        end >= props.subtitle[props.currentIndex + 1].startTime
      ) {
        const start = DT.d2t(end + 0.001);
        props.updateSub(props.subtitle[props.currentIndex + 1], { start });
      } else if (props.currentIndex !== -1 && end > props.subtitle[props.currentIndex + 1].endTime) {
        props.removeSub(props.subtitle[props.currentIndex + 1]);
      }

      setEndFlag(false);
    }
  }, [endflag]);

  return (
    <Style>
      <input
        type="text"
        name="name"
        value={starttime}
        className={startClass}
        onChange={(e) => {
          if (endClass !== 'invalid') {
            setStartTime(e.target.value);
            let start = e.target.value;
            let dateReg = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d\.\d\d\d)/;
            let matches = start.match(dateReg);

            if (!matches) {
              return setStartClass('invalid');
            }
            let starttimeVal = DT.t2d(start);
            if (matches != null && start.length === 12) {
              if (props.subtitle[props.currentIndex].endTime - starttimeVal >= 0.3) {
                setStartClass('');
                props.updateSub(props.subtitle[props.currentIndex], { start });
                setStartFlag(true);
              } else {
                alert(
                  'Invalid start time. Start time cannot be after end time. Please note that if end-time overlaps with subsequent subtitle, the subsequent subtitle will be overwritten accordingly.',
                );
                setStartClass('invalid');
              }
            } else {
              setStartClass('invalid');
            }
          } else {
            alert('end time error');
          }
        }}
      />
      <input
        type="text"
        name="name"
        value={endtime}
        className={endClass}
        onChange={(e) => {
          if (startClass !== 'invalid') {
            setEndTime(e.target.value);
            let end = e.target.value;
            let dateReg = /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d\.\d\d\d)/;
            let matches = end.match(dateReg);

            if (!matches) {
              return setEndClass('invalid');
            }
            let endtimeVal = DT.t2d(end);

            if (matches != null && end.length === 12) {
              if (endtimeVal - props.subtitle[props.currentIndex].startTime >= 0.3) {
                setEndClass('');
                props.updateSub(props.subtitle[props.currentIndex], { end });
                setEndFlag(true);
              } else {
                alert(
                  'Invalid end time. End time cannot be before start time. Please note that if start-time overlaps with subsequent subtitle, the subsequent subtitle will be overwritten accordingly.',
                );
                setEndClass('invalid');
              }
            } else {
              setEndClass('invalid');
            }
          } else {
            alert('start time error');
          }
        }}
      />
    </Style>
  );
}
